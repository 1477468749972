import React from "react"
import { Link } from "gatsby"
import {
  Card,
  Wrapper,
  Category,
  Content,
  Title,
  Excerpt,
  Badge,
} from "./CardElements"

const ImageCard = ({
  category = "Entrepreneurship",
  slug,
  title = "Floyd Mayweather, Jr: the champion of champions",
  updateDate,
  author = "Jne Doe",
  summary = `Floyd Mayweather will be remembered as the most defensive and
  tactical fighter in generations. But as to whether he is the best
  boxer to have ever lived, will always be a question for the world
  to answer.`,
  readMore = "Read more",
  count = 10,
}) => {
  return (
    <Card>
      <Wrapper>
        <div className="header muted">
          <span>Updated at: {updateDate}</span>

          <Category>
            {category} {count !== 0 && <Badge>{count}</Badge>}
          </Category>
        </div>

        <div className="body">
          <Content>
            <span className="muted">{author}</span>
            <Title>
              <Link to={slug}>{title}</Link>
            </Title>
            <Excerpt>{summary}</Excerpt>

            <Link to={slug} className="read-more">
              {readMore} <span>→</span>
            </Link>
          </Content>
        </div>
      </Wrapper>
    </Card>
  )
}

export default ImageCard
