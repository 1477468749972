import styled from "@emotion/styled"

export const Card = styled.div`
  color: var(--white);

  display: grid;
  grid-template-columns: 1.7rem 1fr 1.7rem;
  grid-template-rows: 1fr;

  a {
    color: currentColor;
  }

  .muted {
    color: var(--grey-6);
  }
`

export const Wrapper = styled.div`
  grid-column: 2/3;
  border-radius: 5px;
  background-color: var(--white);
  /* background: url(https://tvseriescritic.files.wordpress.com/2016/10/stranger-things-bicycle-lights-children.jpg)
    center / cover no-repeat; */
  background: url(/people-review-product.jpg) center / cover no-repeat;
  min-height: 54rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.9px 3.8px rgba(0, 0, 0, 0.3),
    0 1.5rem 1.2rem rgba(0, 0, 0, 0.2);

  &:hover {
    .body {
      transform: translateY(0);
    }

    h4 a {
      border-bottom: 1px solid rgba(255, 255, 255, 0.23);
      opacity: 0.75;
      /* line-height: 1.45; */
    }
  }

  .header {
    font-size: 1.2rem;
    padding: 1.25em;
    display: flex;
    justify-content: space-between;
  }

  .body {
    color: var(--white);
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: transform 0.3s;
    transform: translateY(calc(7rem + 4em));
  }
`

export const Category = styled.div``

export const Content = styled.div`
  padding: 1.25em 1.25em 2.7em;
  padding: 1.25em 1.25em 1.6em;
  position: relative;
  z-index: 1;

  a.read-more {
    display: block;
    text-align: center;
    position: relative;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    margin: 0 auto;
    text-align: center;

    &:hover span {
      transition: all 0.3s;
      display: inline-block;
      transform: translateX(0.5rem);
    }
  }
`

export const Title = styled.h4`
  font-size: 3rem;
  font-weight: 300;
  font-variation-settings: "wght" 300;
  line-height: 1.35;
  margin-top: 1.6rem;
  margin-bottom: 2rem;
`

export const Excerpt = styled.div`
  height: 7rem;
  height: 8rem;
  margin: 0;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  max-width: 64ch;
`

/* ------ Media Card ------- */
export const FigureCard = styled.figure`
  color: var(--white);

  display: grid;
  grid-template-columns: 6rem 4vw 1fr;
  grid-template-rows: 2vh 3rem 1fr 2vh;

  a {
    color: currentColor;
  }

  .muted {
    color: var(--grey-6);
  }
`

export const ImgCard = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / -1;
`

export const CardBody = styled.div`
  grid-column: 3 / -1;
`

export const Badge = styled.span`
  border-radius: 50%;
  background-color: var(--white);
  padding: 0.4em;
  color: var(--black);
  font-size: 1em;
  width: 2.5rem;
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  font-weight: 900;
`
