import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

// Components
import Seo from "../../components/seo"
import { CategoryMain } from "../../styles/Layout"
import ImageCard from "../../components/Cards/ImageCard"
import {
  FeaturedImage,
  HeroContent,
  ArticleList,
  // SectionTitle,
} from "../../components/Category/CategoryElements"
import Breadcrumb from "../../components/Breadcrumb/index"
import Newsletter from "../../components/Newsletter"

const ReviewCategoryPage = ({ data }) => {
  const {
    slug,
    title,
    seoDesc,
    description,
    featuredImage,
    category___sub_category,
  } = data.reviewCategory

  const image = getImage(featuredImage)
  const imageSrc = getSrc(featuredImage) // image source for Open Graph

  return (
    <>
      <Seo
        title={title}
        description={seoDesc}
        image={imageSrc}
        imageAlt={title}
        article={false}
      />
      <CategoryMain id="main-content">
        <FeaturedImage>
          <GatsbyImage
            image={image}
            alt={title}
            loading="eager"
            style={{
              height: "59vh",
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
            }}
          />
          <Breadcrumb
            className=""
            categoryTitle={title}
            categoryLink={slug}
            subCategoryTitle={null}
            subCategoryLink={null}
          />

          <HeroContent>
            <h1 className="bg-grad-alt">{title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          </HeroContent>
        </FeaturedImage>

        <ArticleList>
          {category___sub_category.map(subCategory => {
            return (
              <ImageCard
                key={subCategory.id}
                slug={`/reviews/${subCategory.slug}`}
                category={subCategory.title}
                title={subCategory.heading}
                updateDate={subCategory.updatedAt}
                author=""
                summary={subCategory.description.description}
                readMore="View all reviews"
                count={subCategory.review.length}
              />
            )
          })}
        </ArticleList>
        <Newsletter formHeading="our Newsletter" formWidth={""} />
      </CategoryMain>
    </>
  )
}

export default ReviewCategoryPage

export const reviewsQuery = graphql`
  query {
    reviewCategory: contentfulCategory(title: { eq: "Reviews" }) {
      title
      slug
      seoDescription
      heading
      description {
        childMarkdownRemark {
          html
        }
      }
      featuredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          aspectRatio: 1.777777
          quality: 80
        )
      }
      category___sub_category {
        id
        title
        slug
        heading
        description {
          description
        }
        review {
          title
        }
        updatedAt(formatString: "MM/DD/YYYY")
      }
    }
  }
`
